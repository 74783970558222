import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueI18n from '@/libs/i18n/index'

export default function useInventoryItem() {
  // Use toast
  const toast = useToast()
  const i18n = VueI18n

  const refInventoryItemTable = ref(null)
  const today = new Date()
  const firstday = `${today.getFullYear()}-${today.getMonth() + 1}-01`
  const startDateFilter = ref(firstday)
  const lastnumber = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
  const lastday = `${today.getFullYear()}-${today.getMonth() + 1}-${lastnumber}`
  const endDateFilter = ref(lastday)
  const typeFilter = ref('issue')
  const branchFilter = ref(null)
  const categoryFilter = ref(null)
  const reasonFilter = ref(null)

  const refetchData = () => {
    refInventoryItemTable.value.refresh()
  }

  const tableColumns = ref(null)
  tableColumns.value = [
    { key: 'sku', label: i18n.t('SKU'), sortable: true },
    { key: 'item_name', label: i18n.t('Item'), sortable: true },
    { key: 'total_quantity', label: i18n.t('Quantity'), sortable: true },
    { key: 'unit_name', label: i18n.t('Unit'), sortable: true },
    { key: 'price', label: i18n.t('Price'), sortable: true },
    { key: 'total_amount', label: i18n.t('Amount'), sortable: true },
    { key: 'actions', label: i18n.t('Actions') },
  ]

  watch([typeFilter, branchFilter, startDateFilter, endDateFilter, categoryFilter, reasonFilter], () => {
    refetchData()
  })

  const inventoryItemLabels = {
    sku: i18n.t('SKU'),
    item_name: i18n.t('Item'),
    total_quantity: i18n.t('Quantity'),
    unit_name: i18n.t('Unit'),
    price: i18n.t('Price'),
    total_amount: i18n.t('Amount'),
  }

  const inventoryItems = ref({
    sku: null,
    item_name: null,
    total_quantity: 0,
    unit_name: null,
    price: 0,
    total_amount: 0,
  })

  const fetchInventoryItems = (ctx, callback) => {
    store
      .dispatch('inventory_item/fetchAll', {
        subject: 'list',
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
        transaction: typeFilter.value,
        branch_id: branchFilter.value,
        category_id: categoryFilter.value,
        reason_id: reasonFilter.value,
      })
      .then(response => {
        const { inventory_items } = response.data.data
        // eslint-disable-next-line no-param-reassign
        inventoryItems.value = response.data.data.inventory_items.map(({
          item_id, ...keepAttrs
        }) => keepAttrs)
        callback(inventory_items)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting report',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchInventoryItems,
    inventoryItems,
    inventoryItemLabels,
    refInventoryItemTable,
    refetchData,
    tableColumns,

    // Filters
    startDateFilter,
    endDateFilter,
    typeFilter,
    branchFilter,
    categoryFilter,
    reasonFilter,
  }
}
