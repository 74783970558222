<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <inventory-item-filters
        :start-date-filter.sync="startDateFilter"
        :end-date-filter.sync="endDateFilter"
        :type-filter.sync="typeFilter"
        :type-options="typeOptions"
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
        :category-filter.sync="categoryFilter"
        :category-options="categoryOptions"
        :reason-filter.sync="reasonFilter"
        :reason-options="reasonOptions"
      />

      <!-- Table Container Card -->
      <b-card
        class="m-0 p-0"
      >
        <b-card-header class="m-0 pt-0 pr-0 pl-0">
          <b-card-title>{{ $t('Inventory') + $t('Items') }}</b-card-title>

          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
            @show="downloadInventoryItems"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>
            <b-dropdown-item
              href="#"
            >
              <download-csv
                v-if="reportInventoryItem"
                :labels="inventoryItemLabels"
                :data="reportInventoryItem"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </download-csv>
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>

        <b-card-body class="m-0 p-0">
          <b-table
            ref="refInventoryItemTable"
            class="position-relative"
            :items="fetchInventoryItems"
            responsive
            :fields="tableColumns"
            primary-key="item_id"
            show-empty
            empty-text="No matching records found"
            stacked="sm"
          >
            <!-- Column: Quantity -->
            <template #cell(total_quantity)="data">
              {{ Number(data.item.total_quantity).toLocaleString() }}
            </template>
            <!-- Column: Price -->
            <template #cell(price)="data">
              {{ Number(data.item.price).toLocaleString() }}
            </template>
            <!-- Column: Amount -->
            <template #cell(total_amount)="data">
              {{ Number(data.item.total_amount).toLocaleString() }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-link
                class="btn btn-link btn-icon mr-1"
                @click="showItemDetail(data.item.item_id, data.item.item_name)"
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="16"
                />
              </b-link>
            </template>

          </b-table>
        </b-card-body>

      </b-card>

      <!-- modal backdrop item detail -->
      <b-modal
        id="modal-item-detail"
        ok-only
        no-close-on-backdrop
        :title="modalTitle"
        ok-title="Close"
        size="xl"
      >
        <h5 class="mb-1">{{ startDateFilter + ' ' + $t('To') + ' ' + endDateFilter }} &nbsp; {{ $t(typeFilter) + ' ' + $t('Quantity') + ' ' + $t('Total') }} = {{ chartDataTotalQuantity }}
        </h5>

        <apex-line-chart
          :start-date.sync="startDateFilter"
          :end-date.sync="endDateFilter"
          :chart-xaxis.sync="chartXaxis"
          :chart-yaxis.sync="chartYaxis"
          :chart-data.sync="chartData"
        />

      </b-modal>

    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BTable, VBToggle, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem,
  BLink, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import DownloadCsv from 'vue-json-csv'
import VueI18n from '@/libs/i18n/index'
import ApexLineChart from '../chart/ApexLineChart.vue'
import InventoryItemFilters from './InventoryItemFilters.vue'
import useInventoryItem from './useInventoryItem'
import storeModule from '../../common/storeModule'
import inventoryItemStoreModule from './inventoryItemStoreModule'

export default {
  components: {
    InventoryItemFilters,
    BOverlay,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
    BDropdown,
    BDropdownItem,
    DownloadCsv,
    BLink,
    BModal,
    ApexLineChart,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      branchOptions: [],
      categoryOptions: [],
      reasonOptions: [],
      reasonOptionsAll: [],
      reportInventoryItem: [],
      modalTitle: 'Item Detail',
      chartDataTotalQuantity: 0,
      chartData: {
        name: 'Quantity',
        data: [],
      },
      chartXaxis: {
        labels: {
          trim: false,
          rotate: 0,
          minHeight: 40,
          hideOverlappingLabels: true,
        },
        categories: [
        ],
      },
      chartYaxis: {

      },
    }
  },
  watch: {
    branchFilter(newR, oldR) {
      if (newR !== null) {
        console.log(newR)
        this.reasonOptions = this.reasonOptions.filter(obj => obj.branch.includes(newR))
      } else {
        console.log(oldR)
        this.reasonOptions = JSON.parse(localStorage.reasons)
      }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'inventory_item'
    // const thisStore = useStore()

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, inventoryItemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // issue,receipt,adjustment
    const typeOptions = [
      { label: VueI18n.t('Issue'), value: 'issue' },
      { label: VueI18n.t('Receipt'), value: 'receipt' },
      { label: VueI18n.t('Adjustment'), value: 'adjustment' },
    ]

    const {
      fetchInventoryItems,
      inventoryItems,
      inventoryItemLabels,
      refInventoryItemTable,
      refetchData,
      tableColumns,
      // Filters
      typeFilter,
      branchFilter,
      startDateFilter,
      endDateFilter,
      categoryFilter,
      reasonFilter,

    } = useInventoryItem()

    return {

      fetchInventoryItems,
      inventoryItems,
      inventoryItemLabels,
      refInventoryItemTable,
      refetchData,
      tableColumns,
      // Extra Filters
      typeFilter,
      branchFilter,
      startDateFilter,
      endDateFilter,
      categoryFilter,
      reasonFilter,
      typeOptions,
    }
  },
  mounted() {
    this.loadBranches()
    this.loadCategories()
    this.loadReasons()
  },
  methods: {
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadCategories() {
      store
        .dispatch('common/fetchCategories')
        .then(response => {
          const { categories } = response.data.data
          for (let i = 0; i < categories.length; i += 1) {
            this.categoryOptions.push({ label: categories[i].name, value: categories[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadReasons() {
      store
        .dispatch('common/fetchReasons')
        .then(response => {
          const { reasons } = response.data.data
          for (let i = 0; i < reasons.length; i += 1) {
            this.reasonOptions.push({ label: reasons[i].name, value: reasons[i].id.toString(), branch: reasons[i].branch })
          }
          localStorage.reasons = JSON.stringify(this.reasonOptions)
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
    downloadInventoryItems() {
      this.reportInventoryItem = this.inventoryItems
    },
    showItemDetail(itemId, itemName) {
      this.modalTitle = itemName
      store
        .dispatch('inventory_item/fetchAll', {
          subject: 'detail',
          transaction: this.typeFilter,
          item_id: itemId,
          start_date: this.startDateFilter,
          end_date: this.endDateFilter,
          branch_id: this.branchFilter,
          category_id: this.categoryFilter,
          reason_id: this.reasonFilter,
        })
        .then(response => {
          this.chartData.data = []
          const { inventory_items } = response.data.data
          let tqty = 0
          inventory_items.forEach(obj => {
            tqty += Number(obj.quantity)
            this.chartData.data.push(obj.quantity)
            this.chartXaxis.categories.push(obj.inventory_date)
          })
          this.chartDataTotalQuantity = tqty
          this.$bvModal.show('modal-item-detail')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.modal-dialog {
  max-width: 100% !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
